import React, { useRef } from 'react';
import Overlay from '../Shared/Common/Overlay';
import { styled } from '@glitz/react';
import { theme } from '../Theme';
import { GetCart } from '../Cart/Cart';
import useOutsideClick from '../Shared/Hooks/useOutsideClick';
import {
  EventDispatcher,
  ON_MINICART_OPEN_CHANGE,
} from '../Shared/Common/EventDispatcher';
import { media } from '@glitz/core';
import { mediaQuery } from '../Theme/mediaQueries';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import CtaButton from '../Shared/CtaButton/CtaButton';
import TrashIcon from '../Shared/Icons/TrashIcon';
import { EmptyCart } from '../Cart/Cart';

type PropTypes = {
  isOpen?: boolean;
};

function MiniCartModal({ isOpen }: PropTypes) {
  const { cart, isLoading } = GetCart();
  const miniCartModalRef = useRef<HTMLDivElement>(null);

  const {
    translations: {
      'miniCart/heading': headingLabel,
      'miniCart/amountOfProducts': totalProductsLabel,
      'miniCart/totalSum': totalSumLabel,
      'miniCart/goToCart': goToCartLabel,
      'common/emptyCart': emptyCartLabel,
    },
    staticPages: { checkoutPage },
    languageRoute,
  } = useAppSettingsData();

  const getNumberOfCartItems = () => {
    let numberOfItems = 0;
    cart &&
      cart.lineItems &&
      cart.lineItems.map(lineItem => {
        numberOfItems = numberOfItems + lineItem.quantity;
      });
    return numberOfItems;
  };

  const amountOfProductsText = totalProductsLabel.replace(
    '{0}',
    cart && cart.lineItems ? getNumberOfCartItems().toString() : '0'
  );

  useOutsideClick(miniCartModalRef, () => {
    if (isOpen) {
      EventDispatcher.dispatch(ON_MINICART_OPEN_CHANGE, false);
    }
  });

  const onEmptyCart = () => {
    EmptyCart(languageRoute);
  };

  return isOpen ? (
    <>
      <Overlay />
      <Container>
        <Modal ref={miniCartModalRef}>
          <FirstLevel>
            <Head>{headingLabel}</Head>
            <TotalProducts>{amountOfProductsText}</TotalProducts>

            {cart && cart.numberOfItems > 0 && (
              <EmptyCartContainer onClick={() => onEmptyCart()}>
                <StyledTrashIcon />
                <TextLink>{emptyCartLabel}</TextLink>
              </EmptyCartContainer>
            )}
          </FirstLevel>
          <SecondLevel>
            <Price>
              <PriceHeading>{totalSumLabel}</PriceHeading>
              <TotalPrice>{cart.totalPrice}</TotalPrice>
            </Price>
            <StyledCtaButton
              asLink={true}
              href={checkoutPage}
              onClick={() =>
                EventDispatcher.dispatch(ON_MINICART_OPEN_CHANGE, false)
              }
              disabled={cart && cart.lineItems && !!!cart.lineItems.length}
            >
              {goToCartLabel}
            </StyledCtaButton>
          </SecondLevel>
        </Modal>
      </Container>
    </>
  ) : (
    <></>
  );
}

const Container = styled.div({
  ...media(mediaQuery.mediaMinMedium, {
    position: 'relative',
  }),
});

const Modal = styled.div({
  position: 'absolute',
  zIndex: 10,
  top: '60px',
  backgroundColor: theme.white,
  borderRadius: '10px',
  ...media(mediaQuery.mediaMinMedium, { width: '275px', left: '-70px' }),
  ...media(mediaQuery.mediaMaxMedium, {
    right: '15px',
    maxWidth: '275px',
  }),
  ...media(mediaQuery.mediaMinLarge, {
    top: '70px',
    width: '275px',
    left: '-300px',
  }),
});

const FirstLevel = styled.div({
  paddingTop: theme.epsilon,
  paddingLeft: theme.nearGreat,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  marginBottom: theme.delta,
});

const Head = styled.span({
  fontSize: theme.delta,
  fontWeight: 700,
  color: theme.black,
  marginBottom: theme.gamma,
});

const EmptyCartContainer = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
  marginTop: theme.medium,
});

const TextLink = styled.button({
  fontSize: theme.beta,
  color: theme.linkColor,
  textDecoration: 'underline',
  ':hover': {
    cursor: 'pointer',
  },
});

const StyledTrashIcon = styled(TrashIcon, {
  stroke: theme.black,
  marginRight: '10px',
  ':hover': {
    cursor: 'pointer',
  },
});

const TotalProducts = styled.span({
  fontSize: theme.beta,
  color: theme.black,
});

const SecondLevel = styled.div({
  backgroundColor: theme.primaryBackground,
  paddingTop: theme.epsilon,
  padding: { x: theme.nearGreat },
  borderRadius: '10px',
  paddingBottom: theme.spacing(6),
});

const Price = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const PriceHeading = styled.span({
  color: theme.black,
  fontSize: theme.beta,
});

const TotalPrice = styled.span({
  color: theme.black,
  fontSize: theme.delta,
  fontWeight: 700,
});

const StyledCtaButton = styled(CtaButton, {
  marginTop: theme.spacing(4),
  //marginBottom: theme.spacing(6),
  width: '100%',
  textAlign: 'center',
  fontSize: '15px',
  ...media(mediaQuery.mediaMinLarge, {
    minWidth: '100%',
  }),
});

export default MiniCartModal;
