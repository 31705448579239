import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function ContactIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7757 1.74446C19.7954 1.74643 19.815 1.74958 19.8344 1.75389C20.945 2.00064 21.9383 2.61877 22.6502 3.5062C23.3621 4.39364 23.75 5.49732 23.75 6.635V7.52499C23.75 8.05542 23.5393 8.56413 23.1642 8.9392C22.7891 9.31427 22.2804 9.52499 21.75 9.52499H18C17.4696 9.52499 16.9609 9.31427 16.5858 8.9392C16.2107 8.56413 16 8.05542 16 7.52499C16 7.25977 15.8946 7.00542 15.7071 6.81788C15.5196 6.63035 15.2652 6.52499 15 6.52499H9C8.73478 6.52499 8.48043 6.63035 8.29289 6.81788C8.10536 7.00542 8 7.25977 8 7.52499C8 8.05542 7.78929 8.56413 7.41421 8.9392C7.03914 9.31427 6.53043 9.52499 6 9.52499H2.25C1.71957 9.52499 1.21086 9.31427 0.835787 8.9392C0.460714 8.56413 0.25 8.05542 0.25 7.52499L0.25 6.63522C0.249519 5.49724 0.637242 4.3929 1.34912 3.50507C2.06105 2.61718 3.05458 1.99872 4.16556 1.75189C4.18499 1.74757 4.20465 1.74442 4.22442 1.74245C9.36105 0.753305 14.6393 0.753987 19.7757 1.74446ZM4.37955 2.72873C3.49194 2.92663 2.69822 3.42109 2.1293 4.13063C1.55975 4.84094 1.24957 5.7243 1.25 6.63475V7.52499C1.25 7.79021 1.35536 8.04456 1.54289 8.2321C1.73043 8.41963 1.98478 8.52499 2.25 8.52499H6C6.26522 8.52499 6.51957 8.41963 6.70711 8.2321C6.89464 8.04456 7 7.79021 7 7.52499C7 6.99456 7.21071 6.48585 7.58579 6.11077C7.96086 5.7357 8.46957 5.52499 9 5.52499H15C15.5304 5.52499 16.0391 5.7357 16.4142 6.11077C16.7893 6.48585 17 6.99455 17 7.52499C17 7.79021 17.1054 8.04456 17.2929 8.2321C17.4804 8.41963 17.7348 8.52499 18 8.52499H21.75C22.0152 8.52499 22.2696 8.41963 22.4571 8.2321C22.6446 8.04456 22.75 7.7902 22.75 7.52499V6.63498C22.75 5.72483 22.4397 4.84188 21.8701 4.13194C21.3082 3.43144 20.5272 2.94069 19.653 2.73813C19.6419 2.73674 19.6309 2.73499 19.6199 2.73286C14.5868 1.75784 9.41335 1.75717 4.37997 2.73089L4.37955 2.72873Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 11.525C4.02614 11.525 4.25 11.7489 4.25 12.025V18.025C4.25 19.0859 4.67143 20.1033 5.42157 20.8534C6.17172 21.6036 7.18913 22.025 8.25 22.025H15.75C16.8109 22.025 17.8283 21.6036 18.5784 20.8534C19.3286 20.1033 19.75 19.0859 19.75 18.025V12.025C19.75 11.7489 19.9739 11.525 20.25 11.525C20.5261 11.525 20.75 11.7489 20.75 12.025V18.025C20.75 19.3511 20.2232 20.6228 19.2855 21.5605C18.3479 22.4982 17.0761 23.025 15.75 23.025H8.25C6.92392 23.025 5.65215 22.4982 4.71447 21.5605C3.77678 20.6228 3.25 19.3511 3.25 18.025V12.025C3.25 11.7489 3.47386 11.525 3.75 11.525Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 12.775C7.6525 12.775 7.43499 12.841 7.24998 12.9646C7.06498 13.0882 6.92078 13.2639 6.83564 13.4695C6.75049 13.675 6.72821 13.9012 6.77162 14.1195C6.81503 14.3377 6.92217 14.5382 7.07951 14.6955C7.23684 14.8528 7.43729 14.96 7.65552 15.0034C7.87375 15.0468 8.09995 15.0245 8.30552 14.9394C8.51108 14.8542 8.68679 14.71 8.8104 14.525C8.93402 14.34 9 14.1225 9 13.9C9 13.6016 8.88147 13.3155 8.6705 13.1045C8.45952 12.8935 8.17337 12.775 7.875 12.775Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12.775C11.7775 12.775 11.56 12.841 11.375 12.9646C11.19 13.0882 11.0458 13.2639 10.9606 13.4695C10.8755 13.675 10.8532 13.9012 10.8966 14.1195C10.94 14.3377 11.0472 14.5382 11.2045 14.6955C11.3618 14.8528 11.5623 14.96 11.7805 15.0034C11.9988 15.0468 12.225 15.0245 12.4305 14.9394C12.6361 14.8542 12.8118 14.71 12.9354 14.525C13.059 14.34 13.125 14.1225 13.125 13.9C13.125 13.6016 13.0065 13.3155 12.7955 13.1045C12.5845 12.8935 12.2984 12.775 12 12.775Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.125 12.775C15.9025 12.775 15.685 12.841 15.5 12.9646C15.315 13.0882 15.1708 13.2639 15.0856 13.4695C15.0005 13.675 14.9782 13.9012 15.0216 14.1195C15.065 14.3377 15.1722 14.5382 15.3295 14.6955C15.4868 14.8528 15.6873 14.96 15.9055 15.0034C16.1238 15.0468 16.35 15.0245 16.5555 14.9394C16.7611 14.8542 16.9368 14.71 17.0604 14.525C17.184 14.34 17.25 14.1225 17.25 13.9C17.25 13.6016 17.1315 13.3155 16.9205 13.1045C16.7095 12.8935 16.4234 12.775 16.125 12.775Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 16.525C7.65249 16.525 7.43498 16.591 7.24998 16.7146C7.06498 16.8382 6.92078 17.0139 6.83564 17.2195C6.75049 17.425 6.72821 17.6512 6.77162 17.8695C6.81503 18.0877 6.92217 18.2882 7.07951 18.4455C7.23684 18.6028 7.4373 18.71 7.65552 18.7534C7.87375 18.7968 8.09995 18.7745 8.30552 18.6894C8.51108 18.6042 8.68678 18.46 8.8104 18.275C8.93402 18.09 9 17.8725 9 17.65C9 17.3516 8.88148 17.0655 8.6705 16.8545C8.45952 16.6435 8.17337 16.525 7.875 16.525Z"
      />
      <path d="M11.375 16.7146C11.56 16.591 11.7775 16.525 12 16.525C12.2984 16.525 12.5845 16.6435 12.7955 16.8545C13.0065 17.0655 13.125 17.3516 13.125 17.65C13.125 17.8725 13.059 18.09 12.9354 18.275C12.8118 18.46 12.6361 18.6042 12.4305 18.6894C12.225 18.7745 11.9988 18.7968 11.7805 18.7534C11.5623 18.71 11.3618 18.6028 11.2045 18.4455C11.0472 18.2882 10.94 18.0877 10.8966 17.8695C10.8532 17.6512 10.8755 17.425 10.9606 17.2195C11.0458 17.0139 11.19 16.8382 11.375 16.7146Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 16.7146C15.685 16.591 15.9025 16.525 16.125 16.525C16.4234 16.525 16.7095 16.6435 16.9205 16.8545C17.1315 17.0655 17.25 17.3516 17.25 17.65C17.25 17.8725 17.184 18.09 17.0604 18.275C16.9368 18.46 16.7611 18.6042 16.5555 18.6894C16.35 18.7745 16.1238 18.7968 15.9055 18.7534C15.6873 18.71 15.4868 18.6028 15.3295 18.4455C15.1722 18.2882 15.065 18.0877 15.0216 17.8695C14.9782 17.6512 15.0005 17.425 15.0856 17.2195C15.1708 17.0139 15.315 16.8382 15.5 16.7146Z"
      />
    </styled.Svg>
  );
}

export default styled(ContactIcon);
