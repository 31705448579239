import React from 'react';
import { styled, theme } from '../../Theme';
import usePreventBackgroundScroll from '../Hooks/usePreventBackgroundScroll';
import useLockBodyScroll from '../Hooks/useLockBodyScroll';
import { media } from '@glitz/core';
import { mediaQuery } from '../../Theme/mediaQueries';

type PropTypes = {
  clickEvnt?: () => void;
};

function Overlay({ clickEvnt }: PropTypes) {
  useLockBodyScroll();

  return <OverlayDiv onClick={clickEvnt} />;
}

export default Overlay;

const OverlayDiv = styled.div({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.black,
  zIndex: -1,
  transition: {
    property: 'opacity',
    timingFunction: '$main-timing-function',
    duration: '0.3s',
  },
  animationName: {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0.5,
    },
  },
  animationFillMode: 'forwards',
  animationDuration: theme.timings.oneThird,
  ...media(mediaQuery.mediaMinLarge, {
    top: theme.desktopHeaderHeight,
  }),
});
