import React, { useState } from 'react';

import { theme } from '../../../../Theme';
import { styled } from '@glitz/react';
import KexLink from '../../../KexLink/KexLink';
import { LinkType } from '../../../../Enums/LinkType.enum';
import { useAppSettingsData } from '../../../AppSettingsProvider/AppSettingsProvider';
import { media, pseudo } from '@glitz/core';
import { StyleOrStyleArray } from '@glitz/type';
import useMedia from '../../../../Shared/Hooks/useMedia';
import SignInIcon from '../../../../Shared/Icons/SignInIcon';
import ContactIcon from '../../../../Shared/Icons/ContactIcon';
import SignOutIcon from '../../../../Shared/Icons/SignOutIcon';
import ProfileIcon from '../../../../Shared/Icons/ProfileIcon';
import ShoppingBasket from '../../../../Shared/Icons/ShoppingBasket';
import { SignOut } from '../../../../LoginPage/Account';
import MiniCartModal from '../../../../MiniCart/MiniCartModal';
import { GetCart } from '../../../../Cart/Cart';

import {
  EventDispatcher,
  ON_MINICART_OPEN_CHANGE,
} from '../../../../Shared/Common/EventDispatcher';

type PropType = {
  linkType: string;
  label: string;
  href?: string;
  miniCartIsOpen?: boolean;
  isOpen?: boolean;
};

function HeaderIconLink({
  linkType,
  label,
  href,
  miniCartIsOpen = false,
}: PropType) {
  const { requestToken, languageRoute } = useAppSettingsData();

  const { cart } = GetCart();

  const [isHovered, setIsHovered] = useState(false);

  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);

  const getNumberOfCartItems = () => {
    let numberOfItems = 0;
    cart &&
      cart.lineItems &&
      cart.lineItems.map(lineItem => {
        numberOfItems = numberOfItems + lineItem.quantity;
      });
    return numberOfItems;
  };

  const amountOfProducts =
    cart && cart.lineItems ? getNumberOfCartItems() : '0';

  const Icon =
    linkType === LinkType.SignOut
      ? StyledSignOutIcon
      : linkType === LinkType.Contact
      ? StyledContactIcon
      : linkType === LinkType.MiniCart
      ? StyledShoppingBasket
      : linkType === LinkType.MyPages
      ? StyledProfileIcon
      : StyledSignInIcon;

  const iconHoveredStyle: StyleOrStyleArray = {
    fill: isDesktop && isHovered ? theme.white : theme.headerIconGray,
    margin: { xy: 'auto' },
  };

  return (
    <IconLinkContainer
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {href || linkType === LinkType.SignOut ? (
        <StyledKexLink
          href={href}
          onClick={() =>
            linkType === LinkType.SignOut
              ? SignOut(languageRoute, requestToken)
              : undefined
          }
        >
          <Icon css={isDesktop ? iconHoveredStyle : undefined} />
          <Text>{label}</Text>
        </StyledKexLink>
      ) : (
        <StyledCartButton
          onClick={() =>
            EventDispatcher.dispatch(ON_MINICART_OPEN_CHANGE, true)
          }
        >
          <IconWrapper>
            <Icon css={isDesktop ? iconHoveredStyle : undefined} />
            <ProductCircle>
              <ProductCircleAmount>{amountOfProducts}</ProductCircleAmount>
            </ProductCircle>
          </IconWrapper>
          <Text>{label}</Text>
        </StyledCartButton>
      )}
      {LinkType.MiniCart && <MiniCartModal isOpen={miniCartIsOpen} />}
    </IconLinkContainer>
  );
}

const StyledKexLink = styled(KexLink, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  ...media(theme.mediaQuery.mediaMinLarge, {
    justifyContent: 'space-evenly',
  }),
});

const StyledCartButton = styled.button({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  ...media(theme.mediaQuery.mediaMinLarge, {
    justifyContent: 'space-evenly',
  }),
});

const IconWrapper = styled.div({ position: 'relative' });

const ProductCircle = styled.div({
  position: 'absolute',
  top: '-8px',
  left: '18px',
  borderRadius: '50%',
  width: '15px',
  height: '15px',
  backgroundColor: theme.white,
});

const ProductCircleAmount = styled.p({
  fontSize: '8px',
  marginTop: '3px',
  color: theme.primaryDarkBlue,
  fontWeight: 'bold',
});

const Text = styled.span({
  letterSpacing: '0.05em',
  color: theme.white,
  marginTop: '8px',
  textTransform: 'uppercase',
  font: { size: theme.tau, weight: theme.fontWeight.bold },
});

const IconLinkContainer = styled.div({
  display: 'flex',
  flexBasis: '25%',
  justifyContent: 'center',
  ...media(theme.mediaQuery.mediaMinLarge, {
    maxWidth: '70px',
    textAlign: 'center',
    flexBasis: 'unset',
    ...pseudo(':not(:last-child)', {
      marginRight: theme.spacing(6),
    }),
  }),
});

const StyledContactIcon = styled(ContactIcon, {
  height: '20px',
  width: '20px',
  fill: theme.headerIconGray,
  ...media(theme.mediaQuery.mediaMinLarge, {
    height: '24px',
    width: '24px',
  }),
});

const StyledSignInIcon = styled(SignInIcon, {
  height: '20px',
  width: '20px',
  fill: theme.headerIconGray,
});

const StyledSignOutIcon = styled(SignOutIcon, {
  height: '20px',
  width: '20px',
});

const StyledProfileIcon = styled(ProfileIcon, {
  height: '20px',
  width: '20px',
  fill: theme.headerIconGray,
  ...media(theme.mediaQuery.mediaMinLarge, {
    height: '24px',
    width: '24px',
  }),
});

const StyledShoppingBasket = styled(ShoppingBasket, {
  height: '20px',
  width: '20px',
  fill: theme.headerIconGray,
  ...media(theme.mediaQuery.mediaMinLarge, {
    height: '24px',
    width: '24px',
  }),
});

export default HeaderIconLink;
