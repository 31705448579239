import UserCredentials from '../LoginPage/Models/UserCredentials.interface';
import { updateHeader } from '../Shared/Models/Headers/useQueryHeader';
import { updateUserState } from '../Shared/UserContextProvider/useQueryUserState';
import { EmptyCart } from '../Cart/Cart';

export async function SignIn(
  userCredentials: UserCredentials,
  requestToken: string,
  channelId: string,
  setError: (value: boolean) => void,
  setValidationMessage: (value: string) => void,
  setIsLoading: (value: boolean) => void,
  myPageUrl: string
) {
  setIsLoading(true);
  setError(false);
  setValidationMessage('');
  const accountUrl = `/api/${userCredentials.language}/account/`;
  const res = await fetch(`${accountUrl}SignIn`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'litium-request-context': JSON.stringify({
        channelSystemId: channelId,
      }),
      RequestVerificationToken: requestToken,
    },
    body: JSON.stringify(userCredentials),
  });
  const data = await res.json();

  if (data.statusCode === 200) {
    await updateRequestVerificationToken(userCredentials.language, myPageUrl);
    await updateHeader();
    await updateUserState();
  } else {
    setError(true);
    setValidationMessage(data.message);
  }
  setIsLoading(false);
}

export async function SignOut(languageRoute: string, requestToken: string) {
  const accountUrl = `/api/${languageRoute}/account/`;
  const res = await fetch(`${accountUrl}SignOut`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      RequestVerificationToken: requestToken,
    },
  });
  const data = await res.json();

  if (data.statusCode === 200) {
    await updateRequestVerificationToken(languageRoute);
    await EmptyCart(languageRoute);
    await updateHeader();
    await updateUserState();
  }
}

export async function ForgotPassword(
  userName: string,
  requestToken: string,
  languageRoute: string,
  channelId: string,
  setError: (value: boolean) => void,
  setValidationMessage: (value: string) => void,
  kexNavigate: (value: string) => void
) {
  const accountUrl = `/api/${languageRoute}/account/`;
  const res = await fetch(`${accountUrl}ForgotPassword?userName=${userName}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'litium-request-context': JSON.stringify({
        channelSystemId: channelId,
      }),
      RequestVerificationToken: requestToken,
    },
  });
  const data = await res.json();

  if (data.statusCode === 200) {
    updateHeader();
    updateUserState();
    kexNavigate(data.redirectUrl);
  } else {
    setError(true);
    setValidationMessage(data.message);
  }
}

export async function ChangePassword(
  userCredentials: UserCredentials,
  requestToken: string,
  languageRoute: string,
  channelId: string,
  setError: (value: boolean) => void,
  setValidationMessage: (value: string) => void,
  kexNavigate: (value: string) => void
) {
  const accountUrl = `/api/${languageRoute}/account/`;
  const res = await fetch(`${accountUrl}ChangePassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'litium-request-context': JSON.stringify({
        channelSystemId: channelId,
      }),
      RequestVerificationToken: requestToken,
    },
    body: JSON.stringify(userCredentials),
  });
  const data = await res.json();

  if (data.statusCode === 200) {
    updateHeader();
    updateUserState();
    kexNavigate(data.redirectUrl);
  } else {
    setError(true);
    setValidationMessage(data.message);
  }
}

async function updateRequestVerificationToken(
  languageRoute: string,
  redirectUrl?: string
) {
  const accountUrl = `/api/${languageRoute}/account/`;
  const res = await fetch(`${accountUrl}UpdateRequestVerificationToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await res.json();
  window.location.href = `${redirectUrl ? redirectUrl : data.redirectUrl}`;
}
