import React, { useRef, useCallback, useEffect } from 'react';

import { theme, styled } from '../Theme';

import { QuickSearch } from '../SearchPage/Search';

import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { useKexNavigate } from '../Shared/KexRouter/KexRouter';
import { pseudo } from '@glitz/core';

import {
  EventDispatcher,
  ON_MOBILE_SEARCH_OPEN_CHANGE,
  ON_SEARCH_MODAL_OPEN_CHANGE,
  ON_MODAL_SEARCH,
} from '../Shared/Common/EventDispatcher';

import CloseIcon from '../Shared/Icons/CloseIcon';
import PageModelBase from '../Shared/Models/PageModelBase.interface';
import useMedia from '../Shared/Hooks/useMedia';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';

let typeTimer: any;

type PropTypes = {
  onSearchResultChange: any;
  isActive: boolean;
  setIsActive: any;
  modalOpen?: boolean;
  inputValue: string;
  setInputValue?: any;
};

function SearchInput({
  onSearchResultChange,
  isActive,
  setIsActive,
  modalOpen,
  inputValue,
  setInputValue,
}: PropTypes) {
  const {
    translations: { 'search/placeholder': placeholderText },
    staticPages: { searchPage },
    requestToken,
    languageRoute,
  } = useAppSettingsData();
  const { channelId, pageId } = useCurrentPage<PageModelBase>();
  const inputRef = useRef<HTMLInputElement>(null);
  const kexNavigate = useKexNavigate();
  const { organizationId } = useUserStateData();

  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);

  const baseUrl = `/api/${languageRoute}/search/QuickSearch?organizationId=${organizationId}&query=`;

  const doSearch = () => {
    if (inputValue.length > 0) {
      EventDispatcher.dispatch(ON_MOBILE_SEARCH_OPEN_CHANGE, false);
      EventDispatcher.dispatch(ON_SEARCH_MODAL_OPEN_CHANGE, false);
      setInputValue('');
      setIsActive(false);
      inputRef.current?.blur();
      onSearchResultChange(undefined, '');
      kexNavigate(`${searchPage}?searchQuery=${inputValue}`);
    }
  };

  useEffect(() => {
    EventDispatcher.subscribe(ON_MODAL_SEARCH, doSearch);
    return () => {
      EventDispatcher.unsubscribe(ON_MODAL_SEARCH, doSearch);
    };
  });

  const onInputChange = useCallback(
    (value: string) => {
      isDesktop && EventDispatcher.dispatch(ON_SEARCH_MODAL_OPEN_CHANGE, true);
      setIsActive(!!value.length);
      setInputValue(value);
      if (!!value.length) {
        clearTimeout(typeTimer);
        typeTimer = setTimeout(() => {
          let url = baseUrl + value;
          setInputValue(value);
          QuickSearch(url, requestToken, pageId, channelId)
            .then(res => {
              onSearchResultChange(res, value);
            })
            .catch(() => {});
        }, 500);
      } else {
        clearTimeout(typeTimer);
        onSearchResultChange(undefined, '');
        EventDispatcher.dispatch(ON_SEARCH_MODAL_OPEN_CHANGE, false);
      }
    },
    [onSearchResultChange]
  );

  const onInputFocus = () => {
    setTimeout(() => {
      if (!modalOpen && !!inputValue.length) {
        EventDispatcher.dispatch(ON_SEARCH_MODAL_OPEN_CHANGE, true);
        setIsActive(true);
      }
    }, 100);
  };

  const onCrossClick = () => {
    setTimeout(() => {
      setInputValue('');
      setIsActive(false);
      inputRef.current?.focus();
      onSearchResultChange(undefined, '');
    }, 100);
  };

  return (
    <>
      <InputWrapper data-isactive={isActive}>
        <InputField
          onChange={e => onInputChange(e.currentTarget.value)}
          onKeyDown={e => e.key === 'Enter' && doSearch()}
          ref={inputRef}
          type="text"
          placeholder={placeholderText}
          autoFocus={!isDesktop}
          value={inputValue}
          onFocus={onInputFocus}
        />
        {!!inputValue.length && (
          <CloseIconWrapper onClick={onCrossClick}>
            <StyledCloseIcon />
          </CloseIconWrapper>
        )}
      </InputWrapper>
    </>
  );
}

const InputWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.white,
  color: theme.black,
  height: theme.mobileHeaderTopSectionHeight,
  width: '100%',
  borderRadius: '100px',
  padding: { x: theme.spacing(6) },
  fontSize: '15px',
  ...pseudo([':nth-child(n)[data-isActive="true"]'], {
    border: {
      xy: { width: '2px', style: 'solid', color: theme.iconLightBlue },
    },
  }),
});

const InputField = styled.input({
  width: '100%',
  outlineStyle: 'none',
  ...pseudo('::placeholder', {
    color: theme.breadText,
  }),
  ':focus': {
    ...pseudo('::placeholder', {
      color: theme.placeholder,
    }),
  },
});

const CloseIconWrapper = styled.div({
  cursor: 'pointer',
});

const StyledCloseIcon = styled(CloseIcon, {
  height: theme.spacing(4),
});

export default SearchInput;
