import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import Fetcher from '../Shared/Common/Fetcher';
import { IS_PRODUCTION_ENV } from '../Shared/Configs/EnvConfig';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { canUseDOM } from '../Shared/Common/Helpers';
import { EventDispatcher, ON_SEARCH } from '../Shared/Common/EventDispatcher';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import FullSearchResult from '../Search/Models/FullSearchResult.interface';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';
import { AnyMxRecord } from 'dns';

let abortController: AbortController = new AbortController();
let hasMounted = false;

export enum SearchTypes {
  Products = 'getProduct',
  Content = 'getContent',
  Sector = 'getSector',
  Documents = 'getDocument',
  Empty = '',
}

export async function InitSearchResult(
  query: string,
  requestToken: string,
  organizationId: string,
  pageId: string,
  channelId: string,
  languageRoute?: string
) {
  const litiumContext = JSON.stringify({
    currentPageSystemId: pageId,
    productCategorySystemId: pageId,
    channelSystemId: channelId,
  });

  const url = canUseDOM()
    ? `/api/${languageRoute}/search/GetSearchResult?query=${query}&organizationId=${organizationId}&getProduct=true&getContent=true&getDocument=true`
    : '';

  return await FetchSearch(url, litiumContext, requestToken);
}

export async function GetSearchResult(
  query: string,
  pageId: string,
  channelId: string,
  languageRoute: string,
  requestToken: string,
  organizationId: string,
  take: number,
  skip: number,
  type: SearchTypes,
  currentData: FullSearchResult
) {
  const litiumContext = JSON.stringify({
    currentPageSystemId: pageId,
    productCategorySystemId: pageId,
    channelSystemId: channelId,
  });

  const url = canUseDOM()
    ? `/api/${languageRoute}/search/GetSearchResult?query=${query}&organizationId=${organizationId}&${type}=true&take=${take}&skip=${skip}`
    : '';

  var id = '/api/search/GetSearchResult';
  const res = await FetchSearch(url, litiumContext, requestToken);
  if (res) {
    let newRes;
    switch (type) {
      case SearchTypes.Products:
        newRes = {
          ...currentData,
          productSearchResult: {
            ...res.productSearchResult,
            items: [
              ...currentData.productSearchResult.items,
              ...res.productSearchResult.items,
            ],
          },
        };
        break;
      case SearchTypes.Content:
        newRes = {
          ...currentData,
          contentSearchResult: {
            ...res.contentSearchResult,
            items: [
              ...currentData.contentSearchResult.items,
              ...res.contentSearchResult.items,
            ],
          },
        };
        break;
      default:
        newRes = res;
    }

    return newRes;
  }
}

export async function InitCategoryItems(
  requestToken: string,
  organizationId: string,
  languageRoute?: string,
  pageId?: string,
  channelId?: string
) {
  const litiumContext = JSON.stringify({
    currentPageSystemId: pageId,
    productCategorySystemId: pageId,
    channelSystemId: channelId,
  });

  const url = canUseDOM()
    ? `/api/${languageRoute}/category/GetCategoryItems?organizationId=${organizationId}`
    : '';

  return await FetchSearch(url, litiumContext, requestToken);
}

export async function GetCategoryItems(
  pageId: string,
  channelId: string,
  languageRoute: string,
  requestToken: string,
  organizationId: string,
  currentData?: FullSearchResult,
  productionClass?: string,
  sortBy?: string,
  sortDirection?: number
) {
  const litiumContext = JSON.stringify({
    currentPageSystemId: pageId,
    productCategorySystemId: pageId,
    channelSystemId: channelId,
  });

  const url = canUseDOM()
    ? `/api/${languageRoute}/category/GetCategoryItems?organizationId=${organizationId}${
        sortBy ? `&sortBy=${sortBy}` : ''
      }${sortDirection ? `&sortDirection=${sortDirection}` : ''}${
        productionClass ? productionClass : ''
      }`
    : '';

  const res = await FetchSearch(url, litiumContext, requestToken);
  if (res && currentData) {
    let newRes;
    newRes = {
      ...res,
      productSearchResult: {
        ...res.productSearchResult,
        items: [
          ...currentData.productSearchResult.items,
          ...res.productSearchResult.items,
        ],
      },
    };
    return newRes;
  }
  return res;
}

export async function QuickSearch(
  url: string,
  requestToken: string,
  pageId: string,
  channelId: string
) {
  const litiumContext = JSON.stringify({
    currentPageSystemId: pageId,
    productCategorySystemId: pageId,
    channelSystemId: channelId,
  });
  const res = await FetchSearch(url, litiumContext, requestToken);

  return res;
}

function BaseSearch(url: string, id: string, requestToken: string) {
  const [searchResult, setSearchResult] = useState<FullSearchResult>(
    {} as FullSearchResult
  );

  const litiumContext = JSON.stringify({
    currentPageSystemId: '96d94c1b-eb76-4cc7-b09a-3092b3c9aae7',
    productCategorySystemId: '96d94c1b-eb76-4cc7-b09a-3092b3c9aae7',
    channelSystemId: '951186b2-04af-47c4-98c8-3935ed7753e2',
  });
  const { data: fechedSearchResult } = useSWR<FullSearchResult>(
    `${url}-${id}`,
    () => FetchSearch(url, litiumContext, requestToken),
    {
      initialData: undefined,
      revalidateOnFocus: IS_PRODUCTION_ENV,
      dedupingInterval: 0,
    }
  );

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (fechedSearchResult) {
        setSearchResult(fechedSearchResult);
      }
    }
  }, [fechedSearchResult]);

  return searchResult;
}

function FetchSearch(url: string, litiumContext: string, requestToken: string) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;
  EventDispatcher.dispatch(ON_SEARCH, true);

  return Fetcher<FullSearchResult, FullSearchResult>(
    url,
    signal,
    (data, resolve) => {
      resolve(data);
      EventDispatcher.dispatch(ON_SEARCH, false);
    },
    litiumContext,
    requestToken
  );
}
