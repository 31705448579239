import React from 'react';
import { styled } from '../../Theme';
import { StyledProps } from '@glitz/react';

function SignInIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99955 6.07307C6.38052 6.07307 3.4607 8.95842 3.4607 12.5006C3.4607 16.0427 6.38052 18.9281 9.99955 18.9281C13.6186 18.9281 16.5384 16.0427 16.5384 12.5006C16.5384 8.95842 13.6186 6.07307 9.99955 6.07307ZM2.435 12.5006C2.435 8.37661 5.82949 5.04736 9.99955 5.04736C14.1696 5.04736 17.5641 8.37661 17.5641 12.5006C17.5641 16.6245 14.1696 19.9538 9.99955 19.9538C5.82949 19.9538 2.435 16.6245 2.435 12.5006Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99957 1.0257C8.94283 1.0257 7.93098 1.43894 7.1862 2.17195C6.4417 2.9047 6.02497 3.89676 6.02497 4.92944V7.14615C6.02497 7.42939 5.79536 7.659 5.51212 7.659C5.22888 7.659 4.99927 7.42939 4.99927 7.14615V4.92944C4.99927 3.61941 5.5281 2.36472 6.46672 1.44093C7.40506 0.5174 8.67601 0 9.99957 0C11.3231 0 12.5941 0.5174 13.5324 1.44093C14.471 2.36472 14.9999 3.61941 14.9999 4.92944V7.14615C14.9999 7.42939 14.7703 7.659 14.487 7.659C14.2038 7.659 13.9742 7.42939 13.9742 7.14615V4.92944C13.9742 3.89676 13.5574 2.9047 12.8129 2.17195C12.0681 1.43894 11.0563 1.0257 9.99957 1.0257Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99994 10.7256C10.2761 10.7256 10.4999 10.9552 10.4999 11.2384V13.7622C10.4999 14.0454 10.2761 14.2751 9.99994 14.2751C9.7238 14.2751 9.49994 14.0454 9.49994 13.7622V11.2384C9.49994 10.9552 9.7238 10.7256 9.99994 10.7256Z"
      />
    </styled.Svg>
  );
}

export default styled(SignInIcon);
