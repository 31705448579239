import React, { useEffect, useState, useRef } from 'react';

import {
  EventDispatcher,
  ON_SEARCH,
  ON_MOBILE_SEARCH_OPEN_CHANGE,
  ON_SEARCH_MODAL_OPEN_CHANGE,
  ON_MODAL_SEARCH,
} from '../Shared/Common/EventDispatcher';

import Overlay from '../Shared/Common/Overlay';
import { styled, theme } from '../Theme';
import Dots from '../Shared/Button/Dots';
import FullSearchResult from '../Search/Models/FullSearchResult.interface';
import ContentModel from '../Search/Models/ContentModel.interface';
import KexLink from '../Shared/KexLink/KexLink';
import CtaButton from '../Shared/CtaButton/CtaButton';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { media, pseudo } from '@glitz/core';
import useOutsideClick from '../Shared/Hooks/useOutsideClick';
import useMedia from '../Shared/Hooks/useMedia';
import { mediaQuery } from '../Theme/mediaQueries';
import ProductCardModel from '../ProductCard/Models/ProductCardModel.interface';
import SearchLinkResult from '../Search/Models/SearchLinkResult.interface';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';

type PropTypes = {
  searchResult?: FullSearchResult;
  isOpen: boolean;
  currentSearchPhrase: string;
  setInputActive?: any;
  onSearchResultChange?: any;
};

function SearchModal({
  searchResult,
  isOpen,
  currentSearchPhrase,
  setInputActive,
  onSearchResultChange,
}: PropTypes) {
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);
  const {
    translations: {
      'search/showAllSearchResults': showAllSearchResultsLabel,
      'search/pages': pagesLabel,
      'searchPage/products': productsLabel,
      'search/category': categoryLabel,
      'search/planogram': planogramLabel,
      'search/noResults': noResultsLabel,
    },
  } = useAppSettingsData();

  const { authenticated } = useUserStateData();

  const onSearch = (value: boolean) => {
    setIsSearching(value);
  };

  const searchModalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(searchModalRef, () => {
    if (isDesktop && isOpen) {
      EventDispatcher.dispatch(ON_SEARCH_MODAL_OPEN_CHANGE, false);
      setInputActive(false);
    }
  });

  const doSearch = () => {
    if (currentSearchPhrase.length > 0) {
      EventDispatcher.dispatch(ON_MODAL_SEARCH, true);
    }
  };

  const noSearchResult =
    !searchResult?.contentSearchResult &&
    !searchResult?.productSearchResult &&
    ((searchResult?.categoryMatch && !!!searchResult?.categoryMatch.length) ||
      !searchResult?.categoryMatch) &&
    !searchResult?.planogramSearchResult;

  useEffect(() => {
    EventDispatcher.subscribe(ON_SEARCH, onSearch);
    return () => {
      EventDispatcher.unsubscribe(ON_SEARCH, onSearch);
    };
  });

  const onResultItemClick = () => {
    isDesktop
      ? EventDispatcher.dispatch(ON_SEARCH_MODAL_OPEN_CHANGE, false)
      : EventDispatcher.dispatch(ON_MOBILE_SEARCH_OPEN_CHANGE, false);
    setInputActive(false);
    onSearchResultChange(undefined, '');
  };

  return isOpen ? (
    <>
      <Overlay />
      <>
        <SearchResultModalContainer ref={searchModalRef}>
          {isSearching ? (
            <DotsWrapper>
              <Dots />
            </DotsWrapper>
          ) : !isSearching && searchResult && noSearchResult ? (
            <NoResultsWrapper>{noResultsLabel}</NoResultsWrapper>
          ) : (
            <ModalTabsWrapper>
              {searchResult && authenticated && (
                <SearchResultWrapper>
                  <ResultCategory>{productsLabel}</ResultCategory>
                  {searchResult?.productSearchResult?.availableItems && (
                    <ResultList>
                      {searchResult?.productSearchResult?.items.map(
                        (item: ProductCardModel) =>
                          item.url && (
                            <ListItem
                              key={item.url + item.name}
                              onClick={onResultItemClick}
                            >
                              <StyledLink href={item.url}>
                                {item.name}
                              </StyledLink>
                            </ListItem>
                          )
                      )}
                    </ResultList>
                  )}
                </SearchResultWrapper>
              )}
              {searchResult && authenticated && (
                <SearchResultWrapper>
                  <ResultCategory>{planogramLabel}</ResultCategory>
                  {searchResult?.planogramSearchResult?.availableItems && (
                    <ResultList>
                      {searchResult?.planogramSearchResult?.items.map(
                        (item: ProductCardModel) =>
                          item.url && (
                            <ListItem
                              key={item.url + item.name}
                              onClick={onResultItemClick}
                            >
                              <StyledLink href={item.url}>
                                {item.name}
                              </StyledLink>
                            </ListItem>
                          )
                      )}
                    </ResultList>
                  )}
                </SearchResultWrapper>
              )}
              {searchResult && authenticated && (
                <SearchResultWrapper>
                  <ResultCategory>{categoryLabel}</ResultCategory>
                  {!!searchResult?.categoryMatch?.length && (
                    <ResultList>
                      {searchResult?.categoryMatch?.map(
                        (item: SearchLinkResult) => (
                          <ListItem
                            key={item.url + item.name}
                            onClick={onResultItemClick}
                          >
                            <StyledLink href={item.url}>{item.name}</StyledLink>
                          </ListItem>
                        )
                      )}
                    </ResultList>
                  )}
                </SearchResultWrapper>
              )}
              {searchResult && (
                <SearchResultWrapper data-fullwidth={!authenticated}>
                  <ResultCategory>{pagesLabel}</ResultCategory>
                  {searchResult?.contentSearchResult?.availableItems && (
                    <ResultList>
                      {searchResult?.contentSearchResult?.items.map(
                        (item: ContentModel) =>
                          item.src && (
                            <ListItem
                              key={item.src + item.name}
                              onClick={onResultItemClick}
                            >
                              <StyledLink href={item.src}>
                                {item.name}
                              </StyledLink>
                            </ListItem>
                          )
                      )}
                    </ResultList>
                  )}
                </SearchResultWrapper>
              )}
            </ModalTabsWrapper>
          )}
          {!noSearchResult && !isSearching && (
            <BottomSection>
              <Divider />
              <ButtonContainer>
                <ShowAllResultsButton onClick={doSearch}>
                  {showAllSearchResultsLabel}
                </ShowAllResultsButton>
              </ButtonContainer>
            </BottomSection>
          )}
        </SearchResultModalContainer>
      </>
    </>
  ) : (
    <></>
  );
}

const SearchResultModalContainer = styled.div({
  position: 'fixed',
  top: '188px',
  left: theme.spacing(4),
  right: theme.spacing(4),
  bottom: theme.spacing(4),
  overflowX: 'hidden',
  overflowY: 'scroll',
  zIndex: 20,
  backgroundColor: theme.white,
  borderRadius: '10px',
  ...media(theme.mediaQuery.mediaMinLarge, {
    top: '90px',
    bottom: 'unset',
    overflowY: 'unset',
    maxWidth: theme.desktopSearchModalMaxWidth,
    margin: { x: 'auto' },
    maxHeight: theme.desktopSearchModalMaxHeight,
  }),
});

const ModalTabsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  ...media(theme.mediaQuery.mediaMinLarge, {
    flexDirection: 'row',
  }),
});

const SearchResultWrapper = styled.div({
  width: '100%',
  ...media(theme.mediaQuery.mediaMinLarge, {
    width: '23.3%',
    borderRight: {
      style: 'solid',
      width: theme.tiny,
      color: theme.veryLightGray,
    },
    ...pseudo([':last-child'], {
      borderRight: {
        style: 'none',
      },
    }),
    ...pseudo([':first-child'], {
      width: '30%',
    }),
    ...pseudo([':nth-child(n)[data-fullwidth="true"]'], { width: '100%' }),
  }),
});

const StyledLink = styled(KexLink, {
  fontSize: theme.gamma,
  color: theme.linkColor,
  textDecoration: 'underline',
  width: '100%',
  lineHeight: '25.6px',
  padding: { y: theme.spacing(1) },
});

const ShowAllResultsButton = styled(CtaButton, {});

const ListItem = styled.li({ display: 'flex' });

const ResultCategory = styled.div({
  font: { size: theme.beta, weight: theme.fontWeight.bold },
  backgroundColor: theme.lightBlue,
  color: theme.primaryBlue,
  textTransform: 'uppercase',
  padding: { x: theme.spacing(8), y: theme.spacing(3) },
});

const ResultList = styled.ul({
  padding: { x: theme.spacing(8) },
  listStyle: 'none',
  margin: { y: theme.spacing(5) },
});

const DotsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: theme.spacing(9),
});

const NoResultsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  padding: { y: theme.spacing(11) },
});

const Divider = styled.div({
  borderBottom: { width: '1px', style: 'solid', color: theme.grayLine },
});

const BottomSection = styled.div({
  backgroundColor: theme.white,
  marginTop: theme.spacing(10),
  position: 'sticky',
  bottom: '0',
});

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(9),
  ...media(mediaQuery.mediaMinLarge, {
    boxShadow: '0px -3px 11px rgba(0, 0, 0, 0.05)',
    justifyContent: 'left',
    marginLeft: '30px',
  }),
});

export default SearchModal;
