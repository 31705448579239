import React from 'react';

import { theme, styled } from '../../Theme';

import { media, pseudo } from '@glitz/core';
import { mediaQuery } from '../../Theme/mediaQueries';
import { StyledProps } from '@glitz/react';

import KexLink from '../KexLink/KexLink';

type PropTypes = StyledProps & {
  onClick?: () => void;
  asLink?: boolean;
  href?: string;
  children?: any;
  disabled?: boolean;
};

function CtaButton({
  compose,
  onClick,
  asLink,
  href,
  children,
  disabled,
}: PropTypes) {
  return asLink ? (
    <Link
      data-disabled={disabled}
      css={compose()}
      href={!disabled ? href : ''}
      onClick={onClick && onClick}
    >
      <LinkText>{children}</LinkText>
    </Link>
  ) : (
    <Button disabled={disabled} css={compose()} onClick={onClick}>
      <ButtonText>{children}</ButtonText>
    </Button>
  );
}

const Button = styled.button({
  backgroundColor: theme.primaryBlue,
  font: { size: theme.alpha, weight: theme.fontWeight.bold },
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '50px',
  padding: { y: theme.spacing(4), x: theme.spacing(6) },
  ...media(mediaQuery.mediaMinLarge, {
    ':hover': { backgroundColor: theme.primaryDarkBlue },
  }),
  ':disabled': {
    cursor: 'not-allowed',
    backgroundColor: theme.headerIconGray,
    ':hover': { backgroundColor: theme.headerIconGray },
  },
});

const Link = styled(KexLink, {
  backgroundColor: theme.primaryBlue,
  font: { size: theme.alpha, weight: theme.fontWeight.bold },
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '50px',
  padding: { y: theme.spacing(4), x: theme.spacing(6) },
  ...media(mediaQuery.mediaMinLarge, {
    ':hover': { backgroundColor: theme.primaryDarkBlue },
  }),
  ...pseudo([':nth-child(n)[data-disabled="true"]'], {
    cursor: 'not-allowed',
    backgroundColor: theme.headerIconGray,
    ':hover': { backgroundColor: theme.headerIconGray },
  }),
});

const ButtonText = styled.span({
  color: theme.white,
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
});

const LinkText = styled.span({
  padding: { x: theme.spacing(2) },
  color: theme.white,
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
});

export default CtaButton;
