import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function TrashIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={compose()}
    >
      <path d="M1.5 4.5H22.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.25 1.5H9.75C9.35218 1.5 8.97064 1.65804 8.68934 1.93934C8.40804 2.22064 8.25 2.60218 8.25 3V4.5H15.75V3C15.75 2.60218 15.592 2.22064 15.3107 1.93934C15.0294 1.65804 14.6478 1.5 14.25 1.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.75 17.25V9.75" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.25 17.25V9.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.865 21.124C18.8339 21.4991 18.6629 21.8488 18.386 22.1037C18.109 22.3586 17.7464 22.5 17.37 22.5H6.631C6.25462 22.5 5.89198 22.3586 5.61504 22.1037C5.33811 21.8488 5.16711 21.4991 5.136 21.124L3.75 4.5H20.25L18.865 21.124Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </styled.Svg>
  );
}

export default styled(TrashIcon);
