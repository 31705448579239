import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function ProfileIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5C9.37665 5 7.25 7.12665 7.25 9.75C7.25 12.3734 9.37665 14.5 12 14.5C14.6234 14.5 16.75 12.3734 16.75 9.75C16.75 7.12665 14.6234 5 12 5ZM6.25 9.75C6.25 6.57436 8.82436 4 12 4C15.1756 4 17.75 6.57436 17.75 9.75C17.75 12.9256 15.1756 15.5 12 15.5C8.82436 15.5 6.25 12.9256 6.25 9.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.06346 18.286C9.31074 17.767 10.6483 17.4999 11.9992 17.4999C13.3502 17.4999 14.6878 17.767 15.935 18.286C17.1823 18.8049 18.3147 19.5653 19.2669 20.5236C19.4616 20.7194 19.4606 21.036 19.2647 21.2307C19.0688 21.4253 18.7522 21.4243 18.5576 21.2285C17.6983 20.3638 16.6764 19.6775 15.5509 19.2092C14.4254 18.741 13.2183 18.4999 11.9992 18.4999C10.7802 18.4999 9.57313 18.741 8.44759 19.2092C7.32204 19.6775 6.30021 20.3638 5.4409 21.2285C5.24625 21.4243 4.92967 21.4253 4.7338 21.2307C4.53793 21.036 4.53693 20.7194 4.73158 20.5236C5.68384 19.5653 6.81618 18.8049 8.06346 18.286Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM0.25 12C0.25 5.51065 5.51065 0.25 12 0.25C18.4893 0.25 23.75 5.51065 23.75 12C23.75 18.4893 18.4893 23.75 12 23.75C5.51065 23.75 0.25 18.4893 0.25 12Z"
      />
    </styled.Svg>
  );
}

export default styled(ProfileIcon);
