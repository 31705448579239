import React from 'react';
import { styled } from '../../Theme';
import { StyledProps } from '@glitz/react';

function SignOutIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 23.25C19.5563 23.25 23.25 19.5563 23.25 15C23.25 10.4437 19.5563 6.75 15 6.75C10.4437 6.75 6.75 10.4437 6.75 15C6.75 19.5563 10.4437 23.25 15 23.25Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 9.75V5.25C0.75 4.05653 1.22411 2.91193 2.06802 2.06802C2.91193 1.22411 4.05653 0.75 5.25 0.75C6.44347 0.75 7.58807 1.22411 8.43198 2.06802C9.27589 2.91193 9.75 4.05653 9.75 5.25V8.635"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 13.5V16.5"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </styled.Svg>
  );
}

export default styled(SignOutIcon);
