import React from 'react';
import { styled, theme } from '../../Theme';
import { pseudo } from '@glitz/core';

type DotsType = {
  color?: string;
  lineHeight?: string;
};

function Dots({ color, lineHeight }: DotsType) {
  return (
    <>
      <Dot
        css={{
          color: color ? color : theme.primary,
          lineHeight: lineHeight ? lineHeight : 'auto',
        }}
      >
        .
      </Dot>
      <Dot
        css={{
          color: color ? color : theme.primary,
          lineHeight: lineHeight ? lineHeight : 'auto',
        }}
      >
        .
      </Dot>
      <Dot
        css={{
          color: color ? color : theme.primary,
          lineHeight: lineHeight ? lineHeight : 'auto',
        }}
      >
        .
      </Dot>
    </>
  );
}

export default Dots;

// const Wrapper = styled.div({});

const Dot = styled.div({
  display: 'inline-block',
  font: { size: theme.massive },
  animationName: {
    '0%': {
      opacity: '.2',
    },
    '20%': {
      opacity: '1',
    },
    '100%': {
      opacity: '.2',
    },
  },
  animationDuration: theme.timings.sevenFifths,
  animationIterationCount: 'infinite',
  animationFillMode: 'both',
  ...pseudo(':nth-child(2)', {
    animationDelay: theme.timings.oneFifth,
  }),
  ...pseudo(':nth-child(3)', {
    animationDelay: theme.timings.twoFifths,
  }),
});
