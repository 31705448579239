import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import IKexCart from './Models/KexCart.interface';
import Fetcher from '../Shared/Common/Fetcher';
import { IS_PRODUCTION_ENV } from '../Shared/Configs/EnvConfig';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import {
  EventDispatcher,
  NOTIFY_ACTION,
} from '../Shared/Common/EventDispatcher';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';

let abortController: AbortController = new AbortController();
let hasMounted = false;
let cartUrl = '';
let litiumContext = '';
let requestVerificationToken = '';

type CartReturnType = {
  cart: IKexCart;
  isLoading: boolean;
};

export function GetCart(): CartReturnType {
  const tempCartObject: IKexCart = {} as IKexCart;

  const [cart, setCart] = useState<IKexCart>(tempCartObject);
  const { pageCacheTime, languageRoute, requestToken } = useAppSettingsData();
  const { channelId } = useCurrentPage();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  cartUrl = `/api/${languageRoute}/PicturaCart/`;

  litiumContext = JSON.stringify({
    channelSystemId: channelId,
  });

  requestVerificationToken = requestToken;

  const { data: fectchedCart } = useSWR<IKexCart>(
    `${cartUrl}GetCart`,
    () => FetchCart(`${cartUrl}GetCart`, litiumContext),
    {
      initialData: undefined,
      revalidateOnFocus: IS_PRODUCTION_ENV,
      dedupingInterval: pageCacheTime,
    }
  );

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (fectchedCart) {
        setCart(fectchedCart);
        setIsLoading(false);
      }
    }
  }, [fectchedCart]);

  return { cart, isLoading };
}

export async function RemoveFromCart(
  productCode: string,
  languageRoute: string
) {
  cartUrl = `/api/${languageRoute}/PicturaCart/`;
  const res = await fetch(`${cartUrl}RemoveItem?code=${productCode}`, {
    method: 'POST',
    headers: {
      'litium-request-context': litiumContext,
      RequestVerificationToken: requestVerificationToken,
    },
  });
  if (res.ok) {
    const { cart, notification } = await res.json();
    EventDispatcher.dispatch(NOTIFY_ACTION, notification);
    mutate(`${cartUrl}GetCart`, cart, false);
  }
}

export async function UpdateCart(
  productCode: string,
  quantity: number,
  languageRoute: string,
  bookingNumber?: string,
  setIsLoading?: (value: boolean) => void
) {
  setIsLoading && setIsLoading(true);
  cartUrl = `/api/${languageRoute}/PicturaCart/`;
  const updateQuantity = quantity;
  const res = await fetch(
    `${cartUrl}Update?code=${productCode}&bookingNumber=${bookingNumber}&quantity=${updateQuantity}`,
    {
      method: 'POST',
      headers: {
        'litium-request-context': litiumContext,
        RequestVerificationToken: requestVerificationToken,
      },
    }
  );
  if (res.ok) {
    const { cart, notification } = await res.json();
    EventDispatcher.dispatch(NOTIFY_ACTION, notification);
    mutate(`${cartUrl}GetCart`, cart, false);
    setIsLoading && setIsLoading(false);
    return true;
  }
  setIsLoading && setIsLoading(false);
  return false;
}

export async function SetQuantity(
  productCode: string,
  bookingNumber: string,
  quantity: number,
  languageRoute: string,
  setIsLoading?: (value: boolean) => void
) {
  setIsLoading && setIsLoading(true);
  const updateQuantity = quantity;
  cartUrl = `/api/${languageRoute}/PicturaCart/`;
  const res = await fetch(
    `${cartUrl}SetQuantity?code=${productCode}&bookingNumber=${bookingNumber}&quantity=${updateQuantity}`,
    {
      method: 'POST',
      headers: {
        'litium-request-context': litiumContext,
        RequestVerificationToken: requestVerificationToken,
      },
    }
  );
  if (res.ok) {
    const { cart, notification } = await res.json();
    EventDispatcher.dispatch(NOTIFY_ACTION, notification);
    mutate(`${cartUrl}GetCart`, cart, false);
    setIsLoading && setIsLoading(false);
  }
}

export async function EmptyCart(languageRoute: string) {
  cartUrl = `/api/${languageRoute}/PicturaCart/`;
  const res = await fetch(`${cartUrl}RemoveAllItems`, {
    method: 'POST',
    headers: {
      'litium-request-context': litiumContext,
      RequestVerificationToken: requestVerificationToken,
    },
  });
  if (res.ok) {
    const { cart, notification } = await res.json();
    EventDispatcher.dispatch(NOTIFY_ACTION, notification);
    mutate(`${cartUrl}GetCart`, cart, false);
    return true;
  }
}

function FetchCart(url: string, litiumContext: string) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;

  return Fetcher<IKexCart, any>(
    url,
    signal,
    (data, resolve) => {
      if (data.cart) {
        resolve(data.cart);
      }

      resolve(data);
    },
    litiumContext
  );
}
