/* MEDIA VARIABLES */
// Extra small screen / phone
export const screenXs = 480;
export const screenXsMin = screenXs;

// Small screen / tablet
export const screenSm = 768;
export const screenSmMin = screenSm;

// Medium screen / desktop
export const screenMd = 992;
export const screenMdMin = screenMd;

// Large screen / wide desktop
export const screenLg = 1200;
export const screenLgMin = screenLg;

// So media queries don't overlap when required, provide a maximum
export const screenXsMax = screenSmMin - 1;
export const screenSmMax = screenMdMin - 1;
export const screenMdMax = screenLgMin - 1;

export const xsOnly = `(max-width: ${screenXsMax}px)`;

export const smDown = `(max-width: ${screenSmMax}px)`;
export const smUp = `(min-width: ${screenSmMin}px)`;
export const smOnly = `(min-width: ${screenSmMin}px) and (max-width: ${screenSmMax}px)`;

export const mdUp = `(min-width: ${screenMdMin}px)`;
export const mdOnly = `(min-width: ${screenMdMin}px) and (max-width: ${screenMdMax}px)`;

export const lgUp = `(min-width: ${screenLgMin}px)`;

export const contentContainerWidth = 1200;
export const appMaxWidth = 1700;

export const standardDesktopHeaderHeightMdUp = 122;
export const mobileHeaderHeight = 55;
