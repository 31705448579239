import React from 'react';
import { styled } from '../../Theme';
import { StyledProps } from '@glitz/react';

function ShoppingBasket({ compose }: StyledProps) {
  return (
    <styled.Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.90957 10.7503L2.92685 10.75H21.0749L21.0921 10.7503C21.3901 10.7606 21.6819 10.8379 21.9459 10.9765C22.2099 11.1152 22.4392 11.3115 22.6169 11.5509C22.7946 11.7904 22.916 12.0668 22.9721 12.3596C23.0278 12.6501 23.0178 12.9494 22.9429 13.2355L20.8074 22.2251C20.7139 22.6506 20.48 23.0323 20.1433 23.3089C19.8052 23.5866 19.3833 23.742 18.9459 23.7499L18.9369 23.25V23.75H5.05576C4.61837 23.742 4.19647 23.5866 3.85842 23.3089C3.52173 23.0323 3.28786 22.6506 3.19434 22.2252L1.0588 13.2355C0.983913 12.9494 0.973927 12.6501 1.02961 12.3596C1.08574 12.0668 1.20715 11.7904 1.38481 11.5509C1.56247 11.3115 1.79181 11.1152 2.05579 10.9765C2.31977 10.8379 2.61159 10.7606 2.90957 10.7503ZM18.9318 22.75C19.1426 22.7453 19.3456 22.67 19.5086 22.5362C19.6725 22.4015 19.7862 22.2154 19.8312 22.008L19.8333 21.9984L21.9744 12.9856C22.0124 12.8427 22.0178 12.6931 21.99 12.5479C21.9621 12.4026 21.9019 12.2655 21.8138 12.1468C21.7257 12.028 21.6119 11.9307 21.481 11.8619C21.3524 11.7944 21.2104 11.7562 21.0653 11.75H2.93638C2.79128 11.7562 2.64932 11.7944 2.52068 11.8619C2.38976 11.9307 2.27601 12.028 2.1879 12.1468C2.09979 12.2656 2.03957 12.4026 2.01173 12.5479C1.9839 12.6931 1.98915 12.8427 2.0271 12.9857L2.03048 12.9984L4.17058 22.008C4.21557 22.2153 4.32917 22.4015 4.49315 22.5362C4.65608 22.67 4.85915 22.7453 5.06986 22.75H18.9318Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 13.75C7.77614 13.75 8 13.9739 8 14.25V20.25C8 20.5261 7.77614 20.75 7.5 20.75C7.22386 20.75 7 20.5261 7 20.25V14.25C7 13.9739 7.22386 13.75 7.5 13.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 13.75C12.2761 13.75 12.5 13.9739 12.5 14.25V20.25C12.5 20.5261 12.2761 20.75 12 20.75C11.7239 20.75 11.5 20.5261 11.5 20.25V14.25C11.5 13.9739 11.7239 13.75 12 13.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 13.75C16.7761 13.75 17 13.9739 17 14.25V20.25C17 20.5261 16.7761 20.75 16.5 20.75C16.2239 20.75 16 20.5261 16 20.25V14.25C16 13.9739 16.2239 13.75 16.5 13.75Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.47564 1.85399C9.55959 2.11706 9.41438 2.39838 9.15131 2.48233C7.86601 2.89248 6.72455 3.66107 5.86112 4.69775C4.99769 5.73442 4.44821 6.99607 4.27728 8.33434C4.24229 8.60826 3.99187 8.80195 3.71796 8.76696C3.44404 8.73198 3.25035 8.48156 3.28533 8.20764C3.48039 6.68048 4.10744 5.24076 5.09273 4.05777C6.07802 2.87477 7.3806 1.99769 8.8473 1.52966C9.11037 1.44571 9.39169 1.59092 9.47564 1.85399Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5238 1.85411C14.6076 1.59102 14.8889 1.44574 15.152 1.52962C16.6255 1.99939 17.9331 2.8822 18.9196 4.07319C19.9062 5.26418 20.5302 6.71331 20.7175 8.24844C20.7509 8.52255 20.5558 8.77187 20.2817 8.80531C20.0076 8.83876 19.7583 8.64366 19.7248 8.36955C19.5607 7.02443 19.014 5.75468 18.1495 4.7111C17.2851 3.66753 16.1393 2.89399 14.8483 2.48237C14.5852 2.39849 14.4399 2.11721 14.5238 1.85411Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.125 1.25C9.77982 1.25 9.5 1.52982 9.5 1.875V2.625C9.5 2.97018 9.77982 3.25 10.125 3.25H13.875C14.2202 3.25 14.5 2.97018 14.5 2.625V1.875C14.5 1.52982 14.2202 1.25 13.875 1.25H10.125ZM8.5 1.875C8.5 0.977537 9.22754 0.25 10.125 0.25H13.875C14.7725 0.25 15.5 0.977537 15.5 1.875V2.625C15.5 3.52246 14.7725 4.25 13.875 4.25H10.125C9.22754 4.25 8.5 3.52246 8.5 2.625V1.875Z"
      />
    </styled.Svg>
  );
}

export default styled(ShoppingBasket);
